import React from "react"
import "./services.sass"

import Familiar from '../../../static/images/Familiar.jpeg'
import Familiar1 from '../../../static/images/Familiar1.jpeg'
import Familiar2 from '../../../static/images/Familiar2.jpeg'
import Cancer from '../../../static/images/Cancer.jpeg'
import Cancer1 from '../../../static/images/Cancer1.jpeg'
import Ginecologia from '../../../static/images/Ginecologia.jpeg'

import Person from '../../../static/images/person.png'
import People from '../../../static/images/people.png'
import Health from '../../../static/images/health.png'

class Services extends React.Component{

    state = {
        serviceSelected: null
    }

    services = [
        {
            title: "",
          description: ''
        },

    ]

    render (){
        return (
          <div className="services">
            <div className="services__disclaimer">
              <div className="services__disclaimer-title">
                *Toda paciente UNIVERSITARIA, presentando su carnet vigente o
                certificando que se encuentra cursando estudios superiores
                recibirá una tarifa especial en su consulta particular de
                ginecología
              </div>
              <div className="services__disclaimer-item">
                Consulta primera vez</div>
              <div className="services__disclaimer-item">
                Consulta control</div>
              <div className="services__disclaimer-item">
                Teleconsulta
              </div>
              <div className="services__disclaimer-item">
                Citología
              </div>
            </div>

            <div className="services__item">
              <div className="services__item-img-wrap">
                <img className="services__item-img" src={Familiar} alt=""/>
              </div>
              <div className="services__item-content-wrap">
                <img className="services__item-logo" src={Person} alt=""/>
                <h1 className="services__item-title">Consulta de ginecología</h1>
                <p className="services__item-description">
                  Promoción y prevención de patologías ginecológicas. Detección de
                  enfermedades en la mujer, diagnóstico y tratamiento.
                </p>
              </div>
              <div className="services__item-img-wrap">
                <img className="services__item-img" src={Ginecologia} alt=""/>
              </div>
            </div>

            <div className="services__item">
              <div className="services__item-img-wrap">
                <img className="services__item-img" src={Cancer1} alt=""/>
              </div>
              <div className="services__item-content-wrap">
                <img className="services__item-logo" src={People} alt=""/>
                <h1 className="services__item-title">
                  Consulta de prevención de cáncer de cuello uterino
                </h1>
                <p className="services__item-description">
                  Toma de citología cervicovaginal, prueba de detección del Virus del Papiloma
                  Humano (VPH), tratamiento para verrugas genitales, etc...
                </p>
              </div>
              <div className="services__item-img-wrap">
                <img className="services__item-img" src={Cancer} alt=""/>
              </div>
            </div>
            <div className="services__item">
              <div className="services__item-img-wrap">
                <img className="services__item-img" src={Familiar2} alt=""/>
              </div>
              <div className="services__item-content-wrap">
                <img className="services__item-logo" src={Health} alt=""/>
                <h1 className="services__item-title">
                  Consulta de ginecología en planificación familiar
                </h1>
                <p className="services__item-description">
                  Se explican los diferentes métodos de planificación, asesorando cada paciente a elegir el mejor método para planificar.
                </p>
              </div>
              <div className="services__item-img-wrap">
                <img className="services__item-img" src={Familiar1} alt=""/>
              </div>
          </div>
        </div>
        )
    }

}
export default Services
