import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Services from "../components/services/services"

const Servicio = () => (
  <Layout>
    <SEO lang="es" description="Servicios" title="Servicios" />
    <Services></Services>
  </Layout>
)

export default Servicio
